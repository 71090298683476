import {
  Description as HeadlessDescription,
  Label as HeadlessLabel,
  Menu as HeadlessMenu,
  MenuButton as HeadlessMenuButton,
  MenuHeading as HeadlessMenuHeading,
  MenuItem as HeadlessMenuItem,
  MenuItems as HeadlessMenuItems,
  MenuSection as HeadlessMenuSection,
  MenuSeparator as HeadlessMenuSeparator,
  type DescriptionProps as HeadlessDescriptionProps,
  type LabelProps as HeadlessLabelProps,
  type MenuHeadingProps as HeadlessMenuHeadingProps,
  type MenuItemProps as HeadlessMenuItemProps,
  type MenuItemsProps as HeadlessMenuItemsProps,
  type MenuSectionProps as HeadlessMenuSectionProps,
  type MenuSeparatorProps as HeadlessMenuSeparatorProps,
} from "@headlessui/react";
import { IconContext } from "@phosphor-icons/react/dist/lib/context";
import clsx from "clsx";

import { Button } from "../Button";
import type { ButtonProps } from "../Button";
import { Panel, PanelItem } from "../Panel";

import type { IconProps } from "@phosphor-icons/react";
import type React from "react";

const iconContext: IconProps = {
  // defaults, can be overridden by the icon
  color: "var(--icon-color)",
  weight: "fill",
  size: "16px",
};

export function Dropdown(
  props: React.ComponentPropsWithoutRef<typeof HeadlessMenu>
) {
  return <HeadlessMenu {...props} />;
}

export function DropdownButton<T extends React.ElementType>(
  props: ButtonProps & React.ComponentProps<typeof HeadlessMenuButton<T>>
) {
  return <HeadlessMenuButton as={Button} {...props} />;
}

export function DropdownMenu({
  anchor = "bottom",
  portal = true,
  ...props
}: {
  show?: boolean;
  portal?: boolean;
} & HeadlessMenuItemsProps) {
  return (
    <HeadlessMenuItems
      transition
      {...props}
      anchor={portal ? anchor : undefined}
      as={Panel}
    />
  );
}

export function DropdownItem(
  props: React.ComponentPropsWithoutRef<typeof PanelItem> &
    HeadlessMenuItemProps<typeof PanelItem>
) {
  return (
    <IconContext.Provider value={iconContext}>
      <HeadlessMenuItem as={PanelItem} {...props} />
    </IconContext.Provider>
  );
}

export function DropdownHeader({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return (
    <div
      {...props}
      className={clsx(className, "col-span-5 px-3.5 pb-1 pt-2.5 sm:px-3")}
    />
  );
}

export function DropdownSection({
  className,
  ...props
}: HeadlessMenuSectionProps) {
  return (
    <HeadlessMenuSection
      {...props}
      className={clsx(
        className,

        // Define grid at the section level instead of the item level if subgrid is supported
        "col-span-full supports-[grid-template-columns:subgrid]:grid supports-[grid-template-columns:subgrid]:grid-cols-[auto_1fr_1.5rem_0.5rem_auto]"
      )}
    />
  );
}

export function DropdownHeading({
  className,
  ...props
}: HeadlessMenuHeadingProps) {
  return (
    <HeadlessMenuHeading
      {...props}
      className={clsx(
        className,
        "col-span-full grid grid-cols-[1fr,auto] gap-x-12 px-3.5 pb-1 pt-2 text-sm/5 font-medium text-zinc-500 sm:px-3 sm:text-xs/5 dark:text-zinc-400"
      )}
    />
  );
}

export function DropdownSeparator({
  className,
  ...props
}: HeadlessMenuSeparatorProps) {
  return (
    <HeadlessMenuSeparator
      {...props}
      className={clsx(
        className,
        "col-span-full mx-3.5 my-1 h-px border-0 bg-zinc-950/5 sm:mx-3 dark:bg-white/10 forced-colors:bg-[CanvasText]"
      )}
    />
  );
}

export function DropdownLabel({ className, ...props }: HeadlessLabelProps) {
  return (
    <HeadlessLabel
      {...props}
      data-slot="label"
      className={clsx(className, "col-start-2 row-start-1")}
      {...props}
    />
  );
}

export function DropdownDescription({
  className,
  ...props
}: HeadlessDescriptionProps) {
  return (
    <HeadlessDescription
      data-slot="description"
      {...props}
      className={clsx(
        className,
        "col-span-2 col-start-2 row-start-2 text-sm/5 text-zinc-500 group-data-[focus]:text-white sm:text-xs/5 dark:text-zinc-400 forced-colors:group-data-[focus]:text-[HighlightText]"
      )}
    />
  );
}

export function DropdownShortcut({
  className,
  keys,
  ...props
}: { keys: string | string[] } & HeadlessDescriptionProps<"kbd">) {
  return (
    <HeadlessDescription
      as="kbd"
      {...props}
      className={clsx(
        className,
        "col-start-5 row-start-1 flex justify-self-end"
      )}
    >
      {(Array.isArray(keys) ? keys : keys.split("")).map((char, index) => (
        <kbd
          key={index}
          className={clsx([
            "min-w-[2ch] text-center font-sans capitalize text-zinc-400 group-data-[focus]:text-white forced-colors:group-data-[focus]:text-[HighlightText]",

            // Make sure key names that are longer than one character (like "Tab") have extra space
            index > 0 && char.length > 1 && "pl-1",
          ])}
        >
          {char}
        </kbd>
      ))}
    </HeadlessDescription>
  );
}
